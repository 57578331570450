<template>
  <div class="relative min-h-screen md:flex">
    <SideNavBar />

    <div class="flex-1 p-10 text-2xl">
      <!-- header -->
      <div class="flex justify-between flex-wrap md:flex-nowwrap items-center">
        <div class="flex">
          <ChevronLeftIcon
            @click="navigateToManageService"
            class="h-7 w-7 pt-2 cursor-pointer"
          />
          <h2 class="font-semibold">New Service</h2>
        </div>
      </div>
      <!-- end of header -->

      <!-- content -->
      <div v-if="isLoading" class="loader"></div>

      <div v-if="!isLoading" class="mt-5">
        <form class="card pt-2 form-control" @submit.prevent="addService">
          <label class="label"
            ><span class="label-text text-black"
              >Name <span class="text-red-500">*</span></span
            ></label
          >
          <input
            v-model="serviceName"
            type="text"
            class="input input-md input-bordered w-full"
            required
          />

          <label class="label"
            ><span class="label-text text-black"
              >Category <span class="text-red-500">*</span></span
            ></label
          >
          <select
            v-model="selectedCategory"
            @change="selectServiceCategory(selectedCategory)"
            class="select select-bordered w-full"
            required
          >
            <option
              v-for="category in categoryList"
              :key="category.id"
              :value="category.id"
            >
              {{ category.serviceCategoryName }}
            </option>
          </select>

          <!-- start of content section -->
          <div class="card pt-2 mt-3">
            <div class="card-body p-3">
              <h2 class="card-title text-black">Content</h2>
              <label class="label"
                ><span class="label-text text-black">Title</span></label
              >
              <input
                v-model="serviceFirstContentTitle"
                type="text"
                class="input input-md input-bordered w-full"
              />

              <label class="label"
                ><span class="label-text text-black">Description</span></label
              >
              <textarea
                v-model="serviceFirstContentDescription"
                type="text"
                rows="4"
                class="textarea textarea-bordered w-full h-36"
              ></textarea>

              <label class="label"
                ><span class="label-text text-black">Attachment</span></label
              >
              <DragDropImage
                @changed="handleServiceFirstContentAttachment"
                :max="1"
                class="text-black"
                clearAll="Clear All"
                maxError="Maximum one file only"
              />
            </div>
          </div>

          <div class="card pt-2 mt-3">
            <div class="card-body p-3">
              <h2 class="card-title text-black">Second Content</h2>
              <label class="label"
                ><span class="label-text text-black">Title</span></label
              >
              <input
                v-model="serviceSecondContentTitle"
                type="text"
                class="input input-md input-bordered w-full"
              />

              <label class="label"
                ><span class="label-text text-black">Description</span></label
              >
              <textarea
                v-model="serviceSecondContentDescription"
                type="text"
                rows="4"
                class="textarea textarea-bordered w-full h-36"
              ></textarea>

              <label class="label"
                ><span class="label-text text-black">Attachment</span></label
              >
              <DragDropImage
                @changed="handleServiceSecondContentAttachment"
                :max="1"
                class="text-black"
                clearAll="Clear All"
                maxError="Maximum one file only"
              />
            </div>
          </div>

          <div class="card pt-2 mt-3">
            <label class="label"
              ><span class="label-text text-black">Title</span></label
            >
            <input
              v-model="servicePointTitle"
              type="text"
              class="input input-md input-bordered w-full"
            />

            <label class="block text-gray-700 text-xl font-semibold mb-2"
              >Points</label
            >
            <div class="flex flex-wrap">
              <PointCard
                v-for="(item, index) in pointCardComponent"
                :key="item"
                :index="index"
                @deletePointCard="removePointCard"
                :servicePointContent="servicePoints[index]"
              />

              <div
                @click="addComponent"
                class="card mx-2 my-2 cursor-pointer"
                style="width: 47%"
              >
                <svg
                  class="h-full m-auto"
                  height="30"
                  width="30"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 490.1 490.1"
                  style="enable-background: new 0 0 490.1 490.1"
                  xml:space="preserve"
                >
                  <g>
                    <g>
                      <g>
                        <path
                          d="M478.3,454.25c-6.6,0-11.7-5.1-11.7-11.7V23.35H89.1c-6.6,0-11.7-5.1-11.7-11.7s5-11.6,11.7-11.6h389.3c6.6,0,11.7,5.1,11.7,11.7v430.9C490,448.85,484.9,454.25,478.3,454.25z"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            d="M478.3,454.25c-6.6,0-11.7-5.1-11.7-11.7V23.35H89.1c-6.6,0-11.7-5.1-11.7-11.7s5-11.6,11.7-11.6h389.3c6.6,0,11.7,5.1,11.7,11.7v430.9C490,448.85,484.9,454.25,478.3,454.25z"
                          />
                        </g>
                        <path
                          d="M0,82.45v407.6h407.6V82.45H0z M350,286.25c0,12-9.8,21.8-21.8,21.8H225.6v102.7c0,12-9.8,21.8-21.8,21.8l0,0c-12,0-21.8-9.8-21.8-21.8v-102.7H79.3c-12,0-21.8-9.8-21.8-21.8l0,0c0-12,9.8-21.8,21.8-21.8H182v-102.6c0-12,9.8-21.8,21.8-21.8l0,0c12,0,21.8,9.8,21.8,21.8v102.7h102.7C340.2,264.45,350,274.25,350,286.25L350,286.25z"
                        />
                      </g>
                    </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
            </div>
          </div>

          <div class="card pt-2 mt-3">
            <label class="label"
              ><span class="label-text text-black">Title</span></label
            >
            <input
              v-model="serviceBottomTitle"
              type="text"
              class="input input-md input-bordered w-full"
            />

            <label class="label"
              ><span class="label-text text-black">Description</span></label
            >
            <textarea
              v-model="serviceBottomDescription"
              type="text"
              rows="4"
              class="textarea textarea-bordered w-full h-36"
            ></textarea>
          </div>

          <br />
          <button class="btn bg-green-500 border-none mt-3">Submit</button>
        </form>
      </div>
      <!-- end of content -->
    </div>
  </div>
</template>

<script>
import SideNavBar from "../../components/SideNavBar.vue";
import DragDropImage from "../../components/DragDropImage.vue";
import PointCard from "../../components/Service/PointCard.vue";

import { ChevronLeftIcon } from "@heroicons/vue/outline";

export default {
  name: "AddService",
  components: { SideNavBar, DragDropImage, PointCard, ChevronLeftIcon },
  data() {
    return {
      isLoading: false,
      categoryList: [],
      serviceName: "",
      selectedCategory: {},
      serviceFirstContentTitle: "",
      serviceFirstContentDescription: "",
      serviceFirstContentAttachment: [],
      serviceSecondContentTitle: "",
      serviceSecondContentDescription: "",
      serviceSecondContentAttachment: [],
      servicePointTitle: "",
      servicePoints: [
        {
          servicePointTitle: "",
          servicePointDescription: "",
          servicePointAttachments: [],
        },
      ],
      serviceBottomTitle: "",
      serviceBottomDescription: "",
      pointCardComponent: [0], // these index in the array is just for the for-loop to loop three <PointCard>
    };
  },
  mounted() {
    this.getCategoryList();
  },
  methods: {
    handleServiceFirstContentAttachment(files) {
      this.serviceFirstContentAttachment = files[0];
    },
    handleServiceSecondContentAttachment(files) {
      this.serviceSecondContentAttachment = files[0];
    },
    getCategoryList() {
      this.axios({
        url: "/serviceCategory/getServiceCategory",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        this.categoryList = response.data;
      });
    },
    addComponent() {
      this.pointCardComponent.push(0); // randomly push a number to increase the array size
      let servicePointContent = {
        servicePointTitle: "",
        servicePointDescription: "",
        servicePointAttachments: [],
      };
      this.servicePoints.push(servicePointContent);
    },
    removePointCard(index) {
      this.servicePoints[index].servicePointTitle = "";
      this.servicePoints[index].servicePointDescription = "";
      this.servicePoints[index].servicePointAttachments = null;

      document.getElementById(index + "-pointCard").style.display = "none";
    },
    selectServiceCategory(categoryID) {
      this.selectedCategoryID = categoryID;
    },
    addService() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("serviceName", this.serviceName);
      formData.append("serviceCategoryID", this.selectedCategoryID);
      formData.append(
        "serviceFirstContentTitle",
        this.serviceFirstContentTitle
      );
      formData.append(
        "serviceFirstContentDescription",
        this.serviceFirstContentDescription
      );
      formData.append(
        "serviceFirstContentAttachment",
        this.serviceFirstContentAttachment
      );
      formData.append(
        "serviceSecondContentTitle",
        this.serviceSecondContentTitle
      );
      formData.append(
        "serviceSecondContentDescription",
        this.serviceSecondContentDescription
      );
      formData.append(
        "serviceSecondContentAttachment",
        this.serviceSecondContentAttachment
      );
      formData.append("servicePointTitle", this.servicePointTitle);
      formData.append("serviceBottomTitle", this.serviceBottomTitle);
      formData.append(
        "serviceBottomDescription",
        this.serviceBottomDescription
      );

      this.axios
        .post("/service/addService", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (response) => {
          let formData = new FormData();
          formData.append("serviceID", response.data);

          for (var i = 0; i < this.servicePoints.length; i++) {
            var servicePointTitle = this.servicePoints[i].servicePointTitle;
            var servicePointDescription =
              this.servicePoints[i].servicePointDescription;
            var servicePointAttachments =
              this.servicePoints[i].servicePointAttachments;

            formData.append("servicePointTitle[]", servicePointTitle);
            formData.append(
              "servicePointDescription[]",
              servicePointDescription
            );
            formData.append(
              "servicePointAttachments[]",
              servicePointAttachments
            );
          }

          await this.axios
            .post("/service/addServicePoint", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                icon: "success",
                title: response.data["message"],
              });
            });

          this.isLoading = false;
          this.navigateToManageService();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    navigateToManageService() {
      this.$router.push({ name: "ManageService" });
    },
  },
};
</script>

<style scoped>
.container {
  height: unset; /* to override <UploadImage> height*/
}
</style>