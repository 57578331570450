<template>
    <div class="card mx-2 my-2 pt-2 border-pink-200" :id="index + '-pointCard'" style="width: 47%">
        <button @click="deletePointCard()" type="button" class="btn btn-sm bg-red-500 ml-1 border-none">Remove Point</button>
        
        <label class="label"><span class="label-text text-black">Title</span></label>
        <input v-model="servicePointContent.servicePointTitle" type="text" class="input input-md input-bordered w-full">

        <label class="label"><span class="label-text text-black">Description</span></label>
        <textarea v-model="servicePointContent.servicePointDescription" type="text" rows="4" class="text-sm input input-md input-bordered w-full"></textarea>

        <label class="label"><span class="label-text text-black">Attachment</span></label>
        <DragDropImage @changed="handleImage" :max="1" class="text-black" clearAll="Clear All" maxError="Maximum one file only"/>
    </div>
</template>

<script>
import DragDropImage from '../../components/DragDropImage.vue'

export default {
    props: ['index', 'servicePointContent'],
    components: { DragDropImage },
    data(){
        return {

        }
    },
    methods: {
        handleImage(files){
            if (files[0] != null) {
                var reader = new FileReader();
                reader.readAsDataURL(files[0]);

                reader.onload = () => {
                    this.servicePointContent.servicePointAttachments = reader.result;
                };
            }
        },
        deletePointCard(){
            this.servicePointContent.servicePointAttachmentSrc = null
            this.$emit('deletePointCard', this.index)
        },
    },
}
</script>

<style scoped>
.container{
    height: unset; /* to override <UploadImage> height*/
}

@media (max-width: 981px) {
    .card {
        width: 100% !important;
    }
}
</style>